<template>
  <div class="vx-row">
    <div class="vx-col sm:w-full md:w-full mb-base">
      <vx-card title="1. Order Information">
        <!-- FORM END -->
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <small class="ml-1">Job Assign Time</small>
            <flat-pickr :config="flatPickrConfigTime" class="w-full" v-model="formData.job_assign_time"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <small class="ml-1">Unit Ready Time</small>
            <flat-pickr :config="flatPickrConfigTime" class="w-full" v-model="formData.unit_ready_time"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <small>Ship By</small>
            <div class='mt-2'>
                    <input class='mr-2' type='radio' v-model="formData.type" value='internal' vs-value='internal'>
                    <span class='mr-6'>Own Fleet</span>

                    <input class='mr-2' type='radio' v-model="formData.type" value='vendor' vs-value='vendor'>
                    <span class='mr-2'>Vendor Fleet</span>
            </div>
          </div>

          <span v-if='formData.type == "internal"' class='ml-3 mt-2 w-full'>
              <div class="vx-col w-full">
                    <div class="vx-col w-full">
                        <browse-vehicle class="w-full"  name="vehicle_id" label="Fleet" v-model="formData.vehicle_id" :value='formData.vehicle_id'></browse-vehicle>
                    </div>
               </div>
              <div class="vx-col w-full mt-2">
                    <div class="vx-col w-full">
                        <browse-driver class="w-full" :isdriver='true' label='Driver'  name="driver_id" v-model="formData.driver_id" :value='formData.driver_id'></browse-driver>
                    </div>
               </div>
          </span>
          <span v-else-if='formData.type == "vendor"' class='ml-3 mt-2 w-full'>
              <div class="vx-col w-full">
                    <div class="vx-col w-full">
                        <browse-vendor class="w-full"  :isvendor='true' name="vendor_id" label="Vendor" v-model="formData.vendor_id"></browse-vendor>
                    </div>
               </div>
              <div class="vx-col w-full mt-2">
                    <div class="vx-col w-full">
                        <vs-input  class="w-full" label="Fleet No" v-model="formData.no_fleet"></vs-input>
                    </div>
               </div>
              <div class="vx-col w-full mt-2">
                    <div class="vx-col w-full">
                        <vs-input  class="w-full" label="Driver" v-model="formData.driver_name"></vs-input>
                    </div>
               </div>
          </span>

          <div class="vx-col w-full">
            <div class='mt-4'>
                <vs-button color="warning" @click='$router.back()'>Back</vs-button>
                <vs-button class='ml-1' color="success" @click='store'>Save</vs-button>
            </div>
          </div>
        </div>
        <!-- FORM END -->
      </vx-card>
    </div>
    
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import BrowseJobOrderItem from '@browse/JobOrderItem.vue'
import BrowseDeliveryOrder from '@browse/DeliveryOrder.vue'
import BrowseCompany from '@browse/Company.vue'
import BrowseCommodity from '@browse/Commodity.vue'
import BrowseRoute from '@browse/Route.vue'
import BrowseVehicleType from '@browse/VehicleType.vue'
import BrowseVehicle from '@browse/Vehicle.vue'
import BrowseDriver from '@browse/Contact.vue'
import BrowseVendor from '@browse/Contact.vue'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import BrowseJobOrder from '@browse/JobOrder.vue'
import BrowseJobOrderD from '@browse/JobOrderDetail.vue'

export default {
  components:{
    BrowseDeliveryOrder,
    BrowseCompany,
    BrowseJobOrderItem,
    BrowseDriver,
    BrowseVendor,
    BrowseVehicle,
    BrowseCommodity,
    BrowseRoute,
    BrowseVehicleType,
    flatPickr,
    BrowseJobOrder,
    BrowseJobOrderD
  },
  data(){
    return {
        formData : {
            job_assign_time : null,
            unit_ready_time : null,
            type : null,
            vendor_id : null,
            vehicle_id : null,
            driver_id : null,
            no_fleet : null,
            driver_name : null
        },
        flatPickrConfig: {
            altFormat: 'j M Y',
            altInput: true,
            dateFormat: 'Y-m-d',
        },
        flatPickrConfigTime: {
            altFormat: 'j M Y - H:i',
            altInput: true,
            enableTime: true,
            time_24hr: true,
            dateFormat: 'Y-m-d H:i',
        }
    }
  },
  watch:{
    fd_qty(val){
      let value = parseFloat(val)
      if (value > (this.fd_qty_origin - this.fd_transport)) {
        this.fd_qty = this.fd_qty_origin - this.fd_transport
      } else if (!value) {
        this.fd_qty = 0
      }
    }
  },
  methods:{
    ...mapActions({
      dispatchShow: 'delivery_orders/show',
      dispatchUpdate: 'delivery_orders/update',
    }),
    async store(){
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = this.formData
        try {
          if (this.$route.params.id) {
            this.$vs.loading()
            let {data} = await this.dispatchUpdate(payload)
            this.$vs.loading.close()
              this.$vs.notify({
                title: 'Information',
                text: data.message,
                color: 'success'
              })
            this.$emit('on-success', data)
          } else {
            // let {data} = await this.dispatchStore(payload)
            // this.$emit('on-success', data)
          }
        } catch (error) {
            this.$vs.loading.close()
            if(error.status == 422) {
                  for(let x in error.data) {
                      this.$vs.notify({
                        title: 'Oops!',
                        text: error.data[x][0],
                        color: 'danger'
                      })
                      break
                  }
            } else {
                  this.$vs.notify({
                    title: 'Oops!',
                    text: error.data.message,
                    color: 'danger'
                  })
            }
        }
      })
    },
    commodityChange(data){
      this.fd_transport = parseFloat(data.transported)
      this.fd_qty_origin = parseFloat(data.qty)
    },
    append(){

    },
    async getDetail(){
      // let { data } = await this.dispatchShow(this.id)
    },
    deleteTable(i){
      this.details.splice(i, 1)
    },
    appendItem(d) {
        let params = {
          job_order_detail_id: d.job_order_detail_id,
          commodity: d.commodity,
          qty: d.qty,
          qty_origin: d.qty_origin,
          transported: d.transported,
          unit: d.unit,
          volume: d.volume,
          weight: d.qty ? parseFloat(d.weight)/d.qty : 0
        }
        this.details.push(params)
    },
    async getData(){
      let {data} = await this.dispatchShow(this.$route.params.id)
      this.formData = data
    }
  },
  mounted(){
    // console.log(this.ids)
    if (this.$route.params.id) {
        this.getData()
    }
  }
}
</script>

<style>

</style>