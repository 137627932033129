<template>
  <div>
    <form-create :id="$route.params.id" @on-success="$router.back()">
      <template v-slot:footer>
        <vs-button @click="$router.back()" type="border" color="warning">Back</vs-button>
      </template>
    </form-create>
  </div>
</template>

<script>
import FormCreate from './sections/Form.vue'
export default {
  components:{
    FormCreate
  }
}
</script>

<style>

</style>